import styled from 'styled-components';

const HomeContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 50px 100px; /* Increased left and right padding */
  text-align: left;
  background-color: #f9f9f9;
  height: 90vh;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 50px 20px;
  }
`;

const IntroContainer = styled.div`
  flex: 1;
  max-width: 50%;

  & > h1 {
    font-size: 80px;
    margin-bottom: 20px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    background-color: #f9f9f9;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s;
  }

  & > p {
    font-size: 60px;
    margin-bottom: 20px;
    font-family: 'Roboto', sans-serif;
    padding: 10px 20px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    text-align: center;

    & > p {
      font-size: 20px;
    }

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  & > div {
    display: flex;
    padding: 10px 20px;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }
`;

const NavLinksContainer = styled.div`
  flex: 1;
  max-width: 40%;
  flex-direction: column;
  display: flex;
  justify-content: left;

  @media (max-width: 768px) {
    max-width: 100%;
    justify-content: center;
  }
`;

const NavLinks = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  & > a {
    margin: 10px 0;
    padding: 10px 20px;
    font-size: 60px;
    font-family: 'Roboto', sans-serif;
    color: #000;
    background-color: #f9f9f9;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #e0e0e0;
    }
  }

  @media (max-width: 768px) {
    height: auto;

    & > a {
      font-size: 40px;
    }
  }
`;

const Section = styled.div`
  padding: 100px 50px;
  text-align: left;
  background-color: #f9f9f9;

  &:nth-child(even) {
    background-color: #fff;
  }
`;

const IframeContainer = styled.div`
  width: 100%;
  height: 500px;
  border: 1px solid #ccc;
  overflow: hidden;
  border-radius: 10px;
  margin-top: 20px;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
  overflow-x: auto;

  img {
    height: 500px;
    width: auto;
    object-fit: cover;
    border-radius: 10px;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const ContactLink = styled.a`
  color: #004AAC;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s;

  &:hover {
    color: #E37839;
  }
`;

const TechnologiesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
`;

/* ✅ Added missing styled components */
const JobCard = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

const JobHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const JobTitle = styled.h2`
  font-size: 1.5rem;
  color: #004aac;
`;

const JobDate = styled.p`
  font-size: 1rem;
  color: gray;
`;

const JobDescription = styled.div`
  margin-top: 1rem;
  font-size: 1.1rem;
`;

const TechIcon = styled.div`
  width: 50px;
  height: 50px;
  img {
    width: 100%;
    height: auto;
  }
`;
const WorkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  background: #ffffff;
`;

const SectionContainer = styled.div`
  max-width: 1000px;
  width: 100%;
  padding: 20px;
`;

const TextContainer = styled.div`
  width: 100%;
`;

const SectionHeader = styled.div`
  text-align: center;
  margin-bottom: 30px;

  h1 {
    font-size: 32px;
    color: #004aac;
    font-weight: bold;
  }
`;

const ExperienceCard = styled.div`
  display: flex;
  align-items: flex-start;
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  }
`;



const ExperienceContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;

  a {
    font-size: 18px;
    font-weight: bold;
    color: #004aac;
    margin-bottom: 8px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    margin: 5px 0;
    font-size: 16px;
    color: #333;
  }

  span {
    color: #666;
  }
`;


const CompanyLogo = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-right: 20px;
  border-radius: 8px;
  background: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export {
    HomeContainer,
    IntroContainer,
    TechnologiesContainer,
    NavLinksContainer,
    NavLinks,
    Section,
    WorkContainer,
    SectionContainer,
    CompanyLogo,
    ExperienceCard,
    ExperienceContent,
    TextContainer,
    SectionHeader,
    IframeContainer,
    Iframe,
    ImageContainer,
    LinksContainer,
    ContactLink,
    JobCard,
    JobHeader,
    JobTitle,
    JobDate,
    JobDescription,
    TechIcon
};

