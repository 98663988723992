// import React from 'react';
// import { Link } from 'react-scroll';
// import WorkExperience from './WorkExperience';
// import Projects from './ProjectsResearch';
// import { HomeContainer, IntroContainer, NavLinksContainer, NavLinks, Section, LinksContainer, ContactLink } from './Styles';



// function Home() {
//   return (
//     <div>
//       <HomeContainer>
//         <IntroContainer>
//           <h1>Ali Hamza</h1>
//           <p>Welcome to my portfolio. Explore my work in backend development, AI, and more.</p>
//           <div>
//             <p>
//               Feel free to reach out to me at{' '}
//               <ContactLink href="mailto:alihamza19999@gmail.com">alihamza19999@gmail.com</ContactLink>
//             </p>
//             <LinksContainer>
//               <ContactLink href="https://github.com/hurryingauto3" target="_blank" rel="noopener noreferrer">
//                 GitHub
//               </ContactLink>
//               <ContactLink href="https://www.linkedin.com/in/alihamza99/" target="_blank" rel="noopener noreferrer">
//                 LinkedIn
//               </ContactLink>
//               <ContactLink href="https://x.com/HurryingAuto3" target="_blank" rel="noopener noreferrer">
//                 Twitter
//               </ContactLink>
//             </LinksContainer>
//           </div>
//         </IntroContainer>
//         <NavLinksContainer>
//           <NavLinks>
//             <Link to="work" smooth={true} duration={500}>
//               Work
//             </Link>
//             <Link to="research" smooth={true} duration={500}>
//               Projects
//             </Link>
//           </NavLinks>
//         </NavLinksContainer>
//       </HomeContainer>

//       <Section id="work" style={{ height: '90vh' }}>
//         <h1>Work Experience</h1>
//         <WorkExperience />
//       </Section>

//       <Section id="research" style={{ height: '90vh' }}>
//         <h1>Projects & Research</h1>
//         {/* <Projects /> */}
//       </Section>
//     </div>
//   );
// }

// export default Home;


import React from 'react';
import WorkExperience from './WorkExperience';
import { Link } from 'react-scroll';
import { HomeContainer, IntroContainer, Section, LinksContainer, ContactLink, NavLinksContainer, NavLinks } from './Styles';

function Home() {
  return (
    <div>
      <HomeContainer>
        
          {/* Profile Picture */}
          <div>
            <img
              src="/static/me.jpg"
              alt="Ali Hamza"
              style={{
                width: "300px",
                height: "300px",
                borderRadius: "50%",
                objectFit: "cover",
                maxWidth: "100%",
              }}
            />
          </div>

          {/* Intro Section */}
          <IntroContainer>
            <h1 style={{ fontSize: "96px", fontWeight: "bold", margin: 0, textAlign: "left" }}>Ali Hamza</h1>
            <p style={{ fontSize: "36px", marginBottom: "15px", marginTop: "10px", textAlign: "left" }}>
              AI & Software Engineer
            </p>
            <p style={{ fontSize: "18px", lineHeight: "1.6", marginBottom: "20px", textAlign: "left" }}>
              Passionate about <strong>AI systems, backend development, and cloud infrastructure</strong>. I specialize
              in <strong>building efficient, high-performance platforms</strong> leveraging <strong>Python, Django,
              Flask, Kubernetes, and AI-driven automation</strong>.
            </p>
            <div>
              {/* <p style={{ fontSize: "16px", marginBottom: "10px", textAlign: "left" }}>
                🚀 <strong>Expertise in:</strong> Backend Development | AI & ML Systems | Cloud Platforms | DevOps
                <br />
                🔧 <strong>Technologies:</strong> Python, Django, Flask, PostgreSQL, LangChain, GCP, AWS, Docker,
                Kubernetes
              </p> */}
              <LinksContainer>
                <ContactLink href="mailto:alihamza19999@gmail.com">📩 Email Me</ContactLink>
                <ContactLink href="https://github.com/hurryingauto3" target="_blank" rel="noopener noreferrer">
                  🔗 GitHub
                </ContactLink>
                <ContactLink href="https://www.linkedin.com/in/alihamza99/" target="_blank" rel="noopener noreferrer">
                  🔗 LinkedIn
                </ContactLink>
                <ContactLink href="https://x.com/HurryingAuto3" target="_blank" rel="noopener noreferrer">
                  🔗 Twitter
                </ContactLink>
              </LinksContainer>
            </div>
          </IntroContainer>

          {/* Navigation Links */}
          {/* <NavLinksContainer> */}
            {/* <NavLinks> */}
              {/* <Link to="work" smooth={true} duration={500} style={{ textAlign: "left" }}> */}
                {/* Work Experience */}
              {/* </Link> */}
              {/* <Link to="research" smooth={true} duration={500} style={{ textAlign: "left" }}> */}
                {/* Projects */}
              {/* </Link> */}
              {/* <Link to="research" smooth={true} duration={500} style={{ textAlign: "left" }}> */}
                {/* Research */}
              {/* </Link> */}
            {/* </NavLinks> */}
          {/* </NavLinksContainer> */}
      </HomeContainer>

      {/* Work Section */}
      <Section id="work" style={{ padding: "50px 20px" }}>
        <h1 style={{ textAlign: "center", fontSize: "32px", marginBottom: "20px" }}>Work Experience</h1>
        <WorkExperience />
      </Section>

      {/* Projects and Research Section */}
      {/* <Section id="research" style={{ padding: "50px 20px" }}> */}
        {/* <h1 style={{ textAlign: "center", fontSize: "32px", marginBottom: "20px" }}>Projects & Research</h1> */}
      {/* </Section> */}
    </div>
  );
}

export default Home;
