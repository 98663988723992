// import React from 'react';
// import { WorkContainer, SectionContainer, TextContainer, SectionHeader, IframeContainer, Iframe, ImageContainer, TechnologiesContainer } from './Styles';


// function WorkExperience() {
//   return (
//     <WorkContainer>
//       <SectionContainer>
//         <TextContainer>
//           <SectionHeader>
//             <a style={{ color: '#004AAC', textDecoration: 'none' }} href="https://www.bluelake.re" target="_blank" rel="noreferrer"><h1>BlueLake</h1></a>
//             <h2>Vertical Technical Lead, <a style={{ color: '#E37839', textDecoration: 'none' }}
//               href="https://www.jamboreeinsurance.com" target="_blank" rel="noreferrer">Jamboree Insurance</a></h2>
//             <p>Jan 2024 – Present</p>
//           </SectionHeader>
//           <ul>
//             <li>Developed scalable B2C insurance platform using Django, PostgreSQL, OpenAI.</li>
//             <li>Implemented autonomous AI chatbot for customer support, enhancing customer interaction efficiency.</li>
//             <li>Created CI/CD pipeline with Docker, Kubernetes, and GitHub Actions, enabling rapid feature deployment and testing.</li>
//           </ul>
//         </TextContainer>
//         <IframeContainer>
//           <Iframe
//             src="https://www.jamboreeinsurance.com"
//             title="Jamboree Insurance Website"
//           />
//         </IframeContainer>
//       </SectionContainer>

//       <SectionContainer>
//         <TextContainer>
//           <SectionHeader>
//             <a style={{ color: '#004AAC', textDecoration: 'none' }} href="https://www.bluelake.re" target="_blank" rel="noreferrer"><h1>BlueLake</h1></a>
//             <h2>Product Technical Lead; AI and Data, <a style={{ color: '#3F4BBB', textDecoration: 'none' }} href="https://www.homeeasy.com" target="_blank" rel="noreferrer">Homeeasy</a></h2>
//             <p>Jan 2023 – Jan 2024</p>
//           </SectionHeader>
//           <ul>
//             <li>Led a team in developing an AI microservice for client servicing, integrating GPT-4, Claude, and Llama2 within a Flask, Langchain, Kubernetes, and GCP architecture.</li>
//             <li>Developed a building recommendation microservice with PostgreSQL and Flask, reducing recommendation generation time by 50%.</li>
//             <li>Managed a vectorized building information database on Google Cloud SQL, reducing query times by 30%.</li>
//           </ul>

//           <ImageContainer>
//             <img src="/static/flow.png" alt="Homeeasy" />
//             <img src="/static/chat_1.png" alt="Homeeasy" />
//             <img src="/static/chat_2.png" alt="Homeeasy" />
//             <img src="/static/chat_3.png" alt="Homeeasy" />
//           </ImageContainer>

//         </TextContainer>
//       </SectionContainer>

//       <SectionContainer>
//         <TextContainer>
//           <SectionHeader>
//             <a style={{ color: '#004AAC', textDecoration: 'none' }} href="https://www.bluelake.re" target="_blank" rel="noreferrer"><h1>BlueLake</h1></a>
//             <h2>Software Engineer, <a style={{ color: '#3F4BBB', textDecoration: 'none' }} href="https://www.homeeasy.com" target="_blank" rel="noreferrer">Homeeasy</a></h2>
//             <p>Feb 2022 – Dec 2022</p>
//           </SectionHeader>
//           <ul>
//             <li>Collaborated on migrating the platform from a Heroku-based monolithic architecture to a microservices architecture using Docker and Kubernetes.</li>
//             <li>Automated the hiring process with webhooks and Slack actions, reducing hiring time by 50%.</li>
//             <li>Implemented a custom heuristic round-robin load balancing algorithm, improving lead distribution efficiency by 25%.</li>
//             <li>Designed and developed a sales performance reporting tool with Flask, PostgreSQL, Pandas, and Jinja, boosting sales performance by 20%.</li>
//           </ul>
//         </TextContainer>

//       </SectionContainer>

//       <SectionContainer>
//         <TextContainer>
//           <SectionHeader>
//             <h1>My Technologies</h1>
//           </SectionHeader>
          // <TechnologiesContainer>
          //   {/* Languages */}
          //   <a href="https://www.python.org/" target="_blank" rel="noreferrer">
          //     <img src="https://img.icons8.com/?size=100&id=12592&format=png&color=000000" alt="Python" /></a>
          //   <a href="https://www.javascript.com/" target="_blank" rel="noreferrer">
          //     <img src="https://img.icons8.com/?size=100&id=39854&format=png&color=000000" alt="JavaScript" /></a>

          //   <a href="https://www.cplusplus.com/" target="_blank" rel="noreferrer">
          //     <img src="https://img.icons8.com/?size=100&id=40669&format=png&color=000000" alt="C++" />
          //   </a>

          //   <a href="https://www.djangoproject.com/" target="_blank" rel="noreferrer">
          //     <img src="https://img.icons8.com/?size=100&id=XPdRFanRZtNK&format=png&color=000000" alt="Django" />
          //   </a>

          //   <a href="https://flask.palletsprojects.com/" target="_blank" rel="noreferrer">
          //     <img src="https://img.icons8.com/?size=100&id=MHcMYTljfKOr&format=png&color=000000" alt="Flask" />
          //   </a>

          //   <a href="https://nodejs.org/" target="_blank" rel="noreferrer">
          //     <img src="https://img.icons8.com/?size=100&id=FQlr_bFSqEdG&format=png&color=000000" alt="Nodejs" />
          //   </a>

          //   <a href="https://reactjs.org/" target="_blank" rel="noreferrer">
          //     <img src="https://img.icons8.com/?size=100&id=122637&format=png&color=000000" alt="React" />
          //   </a>

          //   <a href="https://www.postgresql.org/" target="_blank" rel="noreferrer">
          //     <img src="https://img.icons8.com/?size=100&id=36440&format=png&color=000000" alt="PostgreSQL" />
          //   </a>

          //   <a href="https://www.mongodb.com/" target="_blank" rel="noreferrer">
          //     <img src="https://img.icons8.com/?size=100&id=bosfpvRzNOG8&format=png&color=000000" alt="MongoDB" />
          //   </a>

          //   <a href="https://www.redis.io/" target="_blank" rel="noreferrer">
          //     <img src="https://img.icons8.com/?size=100&id=6SNSaS7LDAXE&format=png&color=000000" alt="Redis" />
          //   </a>

          //   <a href="https://www.docker.com/" target="_blank" rel="noreferrer">
          //     <img src="https://img.icons8.com/?size=100&id=sIsJJux4DVLR&format=png&color=000000" alt="Docker" />
          //   </a>

          //   <a href="https://kubernetes.io/" target="_blank" rel="noreferrer">
          //     <img src="https://img.icons8.com/?size=100&id=vgVcFHeBHXNm&format=png&color=000000" alt="Kubernetes" />
          //   </a>

          //   <a href="https://www.git-scm.com/" target="_blank" rel="noreferrer">
          //     <img src="https://img.icons8.com/?size=100&id=38389&format=png&color=000000" alt="Git" />
          //   </a>

          //   <a href="https://www.github.com/" target="_blank" rel="noreferrer">
          //     <img src="https://img.icons8.com/?size=100&id=62856&format=png&color=000000" alt="GitHub" />
          //   </a>

          //   <a href="https://www.cloud.google.com/" target="_blank" rel="noreferrer">
          //     <img src="https://img.icons8.com/?size=100&id=WHRLQdbEXQ16&format=png&color=000000" alt="Google Cloud" />
          //   </a>

          //   <a href="https://www.aws.amazon.com/" target="_blank" rel="noreferrer">
          //     <img src="https://img.icons8.com/?size=100&id=VoXRGxL3ekkk&format=png&color=000000" alt="AWS" />
          //   </a>

          //   <a href="https://www.heroku.com/" target="_blank" rel="noreferrer">
          //     <img src="https://img.icons8.com/?size=100&id=32343&format=png&color=000000" alt="Heroku" />
          //   </a>

          // </TechnologiesContainer>
//         </TextContainer>
//       </SectionContainer>
//     </WorkContainer>
//   );
// }

// export default WorkExperience;

import React from 'react';
import { WorkContainer, SectionContainer, TextContainer, SectionHeader, ExperienceCard, CompanyLogo, ExperienceContent, TechnologiesContainer } from './Styles';

const experiences = [
  {
    company: "BlueLake",
    link: "https://www.bluelake.re",
    role: "Software Engineer",
    date: "Jan 2022 – Sept 2024",
    description: "Built scalable platforms and AI-driven solutions for the real estate sector.",
    logo: "/static/bluelake.jpeg"
  },
  {
    company: "World Wildlife Fund",
    link: "https://www.worldwildlife.org/",
    role: "Software Engineering Intern",
    date: "June 2021 – Sept 2021",
    description: "Developed cloud-based systems for wildlife image processing pipelines.",
    logo: "/static/wwf.jpeg"
  },
  {
    company: "Virufy",
    link: "https://www.virufy.org/",
    role: "Software Engineering Intern",
    date: "Apr 2020 – June 2020",
    description: "Created COVID-19 audio analysis tools and crowdsourced clinical datasets.",
    logo: "/static/virufy.jpeg"
  }
];

function WorkExperience() {
  return (
    <WorkContainer>
      <SectionContainer>
        <TextContainer>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>

              {experiences.map((job, index) => (
                <a
                  href={job.link}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <ExperienceCard key={index}>
                    <CompanyLogo src={job.logo} alt={`${job.company} Logo`} />
                    <ExperienceContent>

                      <p style={{ fontSize: "18px", fontWeight: "bold", color: "#004aac", margin: "0 0 8px 0" }}>{job.company}</p>

                      <p style={{ margin: "5px 0", fontSize: "16px", color: "#333" }}>
                        {job.role} 
                        <p style={{ fontSize: "14px", color: "#555" }}>
                        <span style={{ color: "#666" }}>{job.date}</span>
                          </p>
                      </p>
                      <p style={{ fontSize: "14px", color: "#555", lineHeight: "1.6" }}>{job.description}</p>
                    </ExperienceContent>
                
                  </ExperienceCard>
                </a>
              ))}
            </div>

        </TextContainer>
      </SectionContainer>
    </WorkContainer>
  );
}

export default WorkExperience;